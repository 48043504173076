.btn-file {
    position: relative;
    overflow: hidden;
}

.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}

.imgTop {
    padding-top: 40px;
}

#img-upload {
    width: 100%;
    height: 200px;
}

.imageUpload {
    margin-top: 45px;
}

.imageArea {
    min-height: 120px;
    width: 100%;
    margin-top: 5px;
    cursor: pointer;
}

.cursor-pointer {
    cursor: pointer;
}

.pl-10 {
    padding-left: 10px;
}

.ml-15 {
    margin-left: 15px;
    width: 73px;
}

.house {
    background-color: var(--lightGray);
}

.house-form {
    background-color: white;
    border-radius: 10px;
    color: var(--gray1);
}

.house-header {
    min-height: 50px;
    border-bottom: var(--defaultBorder);
    margin: 0px;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.t15 {
    top: 15px;
}

.house-header .active {
    width: 22px;
    height: 22px;
    background-color: var(--orange);
    border-radius: 50%;
    color: var(--white);
    margin-right: 2px;
}

.house-header .active-title {
    color: var(--gray1);
    font-weight: bold;
}

.house-header .inactive {
    width: 22px;
    height: 22px;
    border: 1px solid var(--gray1);
    border-radius: 50%;
}

.house-header .inactive-title {
    color: var(--blue);
    font-weight: 100;
    margin-left: 5px;
}

.btn-default {
    border-radius: 5px;
    font-size: 12px;
    margin: 0px 1px;
}

.btn-primary {
    background-color: var(--blue);
    color: var(--white);
    border-radius: 5px;
    font-size: 12px;
    margin: 0px 1px;
}

.btn-secondary {
    background-color: var(--white);
    color: var(--blue);
    border: 1px solid var(--lightBlue);
    border-radius: 5px;
    font-size: 12px;
    margin: 0px 1px;
}

.addNewItem {
    background-color: white;
    color: var(--blue);
    margin-top: 10px;
    margin-right: 20px;
    font-weight: 500;
    border-color: var(--blue);
}

.addNewItemlogo {
    background-color: white;
    color: var(--blue);
    margin-top: 20px;
    margin-right: 6px;
    font-weight: 100;
    font-size: large;
    cursor: pointer;
    border-color: var(--blue);
}

.addNewItemlogo1232 {
    background-color: white;
    color: var(--blue);
    margin-top: -10px !important;
    margin-right: 6px;
    font-weight: 100;
    font-size: large;
    cursor: pointer;
    border-color: var(--blue);
}

.addNewItemlogo1 {
    background-color: white;
    color: var(--blue);
    margin-top: 20px;
    /* margin-right: 6px; */
    font-weight: 100;
    font-size: large;
    cursor: pointer;
    border-color: var(--blue);
}

.dflex {
    display: flex;
}

.addNewItem:hover {
    background-color: white;
    color: var(--blue);
    border-color: var(--blue);
}

.house-btn1 {
    margin-left: 5px;
    background-color: var(--white);
    border-color: var(--blue);
    color: var(--blue);
}

.house-btn2 {
    margin-left: 5px;
    background-color: var(--white);
    color: var(--gray1);
    pointer-events: none;
}

.pt-pb-10 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.radio-btn {
    color: var(--gray1);
    background-color: var(--white);
    border-color: var(--lightBlue);
    padding: 5px 10px 0px 10px;
}

.pb-2 {
    padding-bottom: 2%;
    padding-top: 2%;
}

.addHmo {
    margin-top: 20px;
}

#text {
    margin-left: 48px;
}

#numbers {
    padding: 1px;
    margin-left: 10px;
}

#tab-title {
    margin-left: 10px;
}

.image {
    width: 90%;
}

.attachments {
    width: 100%;
    font-size: 14px;
    margin: 2px;
    padding: 5px;
}

#down {
    padding: 5px;
    margin: 3px;
}

.attachments1 {
    width: 90%;
    /* margin-top: 20px; */
    font-size: 12px;
    top: 100px;
}

#down1 {
    margin: 1px;
}

.blueIcon {
    color: var(--blue);
    cursor: pointer;
}

.mt-80 {
    margin-top: 80px;
}

.font-size-20 {
    font-size: 20px;
}