.fluid-div {
  padding: 0px !important;
  margin: 0px !important;
}

.inner-bg {
  background-color: var(--disableColor);
}

.container-header {
  padding-bottom: 5px;
  height: 80px;
  margin: 0px !important;
}

.dropdown-menu>li>a {
  display: block;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: var(--blue) !important;
  white-space: nowrap;
}

.dropdown-menu>li {
  padding: 5px 30px 5px 4px !important;
}

.houseDropDown {
  background-color: var(--gray5);
  border: 1px solid var(--lightBlue) !important;
  border-radius: 20px;
  margin-right: 5px;
}

.navbar-fixed-top {
  z-index: 1;
}

.house-selected {
  background-color: var(--lightBlue) !important;
}

.list-none {
  padding-left: 0px;
  list-style-type: none;
}