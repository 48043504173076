.dashboard {
    background-color: var(--lightGray);
    padding-top: 2px;
    padding-bottom: 2px;
}

.card {
    border: var(--defaultBorder);
    border-radius: 10px;
    background-color: var(--white);
    margin-left: 15px;
    margin-top: 10px;
    max-width: 300px;
}

.card-img-top {
    width: 270px;
    height: 75px;
    margin-top: 15px;
    border-radius: 10px;
}

.card-body {
    font-size: 12px;
    padding-bottom: 10px;
    padding-top: 10px;
}

.plr-0 {
    padding-left: 0;
    padding-right: 0;
}

.small,
small {
    font-size: 70%;
}

.card-title {
    display: block;
    font-weight: bold;
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.pt-10 {
    padding-top: 10px;
}

.ml--5 {
    margin-left: -5%;
}

.fl {
    float: left;
}

.fr {
    float: right;
}

.h35 {
    height: 35px;
}

.pl-10 {
    padding-left: 10px;
}

.w30 {
    width: 30%;
    display: inline-block;
}

.w40 {
    width: 40%;
    display: inline-block;
}

.dashboard-btn {
    width: 100%;
    height: 25px;
    border-radius: 20px;
    padding: 0px;
    color: var(--blue);
    background-color: var(--disableColor);
    font-weight: 600;
    margin-top: 15px;
}

.progress-div {
    background-color: var(--lightGray);
    border-radius: 10px;
}

.progress {
    background-color: var(--orange);
    height: 10px;
    border-radius: 10px;
    margin-bottom: 0;
}

.property-btn {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: white;
    border-radius: 0px;
    width: 150px;
    margin-top: 5px;
}

.active-btn {
    background-color: var(--blue);
    color: white;
}

.small-card {
    padding: 10px;
    border: var(--defaultBorder);
    border-radius: 10px;
    background-color: var(--white);
    margin-left: 15px;
    margin-top: 10px;
}

.small-card-width {
    max-width: 315px;
}

.small-card-btn {
    width: 25px;
    height: 25px;
    border-radius: 15px;
    padding: 0px;
    color: var(--blue);
    background-color: var(--disableColor);
    border: 1px;
}

.m-l-4 {
    margin-left: 4px;
}

.ml-25 {
    margin-left: 25%;
}

.min-chart-span {
    float: left;
    clear: left;
}

.propertyCard {
    border-top: 1px solid #B0D0F4;
}

.selectedCard {
    box-shadow: 0px 0px 5px 5px #b0d0f4, 2px 6px 20px 0 #b0d0f4;
    border: 1px solid #4559fa;
    border-radius: 15px;
}

.sliderCard {
    margin: 3px;
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}

.slick-prev:before,
.slick-next:before {
    color: #4559fa !important;
}

.justify-between {
    display: flex;
    justify-content: space-between;
}

.dasboard-cards-container {
    margin-left: 2% !important;
    margin-right: 2% !important;
}