.footer {
    font-weight: 350;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #777;
}

.info-logo {
    background-color: transparent;
    color: var(--blue);
    margin-right: 6px;
    font-weight: 100;
    font-size: large;
    cursor: pointer;
    border-color: var(--blue);
}

.list-flex {
    display: flex;
    justify-content: space-between;
}

.model {
    display: flex;
    align-items: center;
    justify-content: center;
}

.paper {
    /* background-color: theme.palette.background.paper; */
    border: '2px solid #000';
    /* box-shadow: theme.shadows[5]; */
    /* padding: theme.spacing(2, 4, 3) */
}

.active_status {
    background-color: #12AD2B;
    border-radius: 10px;
    color: #fff;
    padding: 2px 8px;
}

.expired_status {
    background-color: #FFAE42;
    border-radius: 10px;
    padding: 2px 8px;
}

.renewed_status {
    background-color: #38ACEC;
    border-radius: 10px;
    padding: 2px 8px;
}

.foreclosed_status {
    background-color: #FF4500;
    color: #fff;
    border-radius: 10px;
    padding: 2px 8px;
}

.alignRight {
    text-align: right;
}

.width-82 {
    min-width: 82px;
    width: 82px;
}

.mt-3 {
    margin-top: 3px;
}

.pt-10 {
    padding-top: 10pxx;
}

.align-center {
    align-items: center;
}

.inner-container-title {
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    font-weight: 800;
    line-height: 30px;
}

.inner-container {
    position: absolute;
    top: 30px;
    bottom: 20px;
    left: 0px;
    right: 0px;
    overflow: auto;
    margin-top: 5px;
    padding-bottom: 10px;
    background-color: var(--lightGray);
}

.inner-container.full {
    bottom: 0px;
}

.inner-footer {
    position: absolute;
    bottom: 0px;
    height: 40px;
    left: 0px;
    right: 0px;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    margin-left: 10px !important;
    margin-right: 10px !important;
    padding: 0px !important;
    display: flex;
    background-color: #EAECF7;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
}


.inner-footer .right-button {
    text-align: right;
    padding-right: 25px;
}

.inner-form {
    background-color: white !important;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    color: var(--gray1);
    margin-left: 10px;
    margin-right: 10px;
    padding-bottom: 10px;
}


.modal-dialog-scrollable {
    display: flex;
    max-height: 90%;
}

.modal-dialog-scrollable .modal-body {
    overflow-y: auto;
    max-height: 80%;
}

.modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-footer {
    flex-shrink: 0;
}

.text-align-left {
    text-align: left !important;
}
