.dataTables_filter input {
    border-color: #ddd;
    border-radius: 5px;
}

table.dataTable thead th,
table.dataTable thead td {
    border-bottom: 1px;
}

table td {
    border-bottom: 1px !important;
}

table.dataTable.no-footer {
    border-bottom: 1px solid #ddd !important;
}


#example_wrapper {
    margin-left: 10px;
    margin-right: 10px;
}

.contact {
    background-color: var(--lightGray);
}

.modal {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.25);
    display: block;
    overflow-y: scroll;
}

.emaillabel {
    margin-left: 25px;
}

.modal-title {
    text-align: center;
    font-weight: 700 !important;
    display: contents;
}

.close {
    color: var(--gray1);
    float: right;
    margin-top: -20px;
}

.close:hover {
    color: var(--gray3);
    cursor: pointer;
}

.mt-25 {
    margin-top: 25px;
}

.inner-popup {
    border-radius: 5px;
    padding-top: 5px;
}

.title {
    color: var(--gray3);
}

.company {
    font-weight: 600;
    color: var(--blue);
}

#home1 {
    margin-left: 20px;
}

#home {
    font-weight: 600;
    position: relative;
    right: 14px;
    top: 2px;
}

#homecost {
    height: 15px;
    width: 15px;
}

#homecost:checked {
    width: 15px;
    height: 15px;
    position: relative;
    background-color: var(--green);
    content: '';
    display: inline-block;
    visibility: visible;
}

.bothmo_email {
    margin-top: 30px;
}

.d_flex {
    display: flex;
    align-items: center;
}

.mt-10 {
    margin-top: 10px;
}



/**Data Table*/