@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading-spinner {
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3;
    /* Light grey */
    border-top: 10px solid #383636;
    /* Black */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
    top: 50%;
    left: 50%;
    position: absolute;
}

.spinner-container {
    justify-content: center;
    align-items: center;
    display: block;
    background-color: rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    opacity: 0.7;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
}