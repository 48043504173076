.bg {
    background-color: var(--lightGray);
}

.enable-border {
    color: var(--lightBlue);
}

.disable-border {
    color: var(--disableColor);
}

.heading-color {
    color: var(--gray1);
}

.subheading-color {
    color: var(--gray2);
}

.primary-color {
    color: var(--gray3);
}

.primary-button {
    color: var(--blue);
}

.select-color {
    color: var(--green);
}

.login_home_img {
    height: 400px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.login_left {
    vertical-align: middle;
    justify-content: center;
    align-items: center;
}

.login_right {
    height: 100vh;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-container {
    border-radius: 15px;
    border: 1px solid #B0D0F4;
    margin: 0 auto;
    width: 100%;
    min-height: 350px;
}

.full-container {
    position: absolute;
    top: 0px;
    bottom: 25px;
    overflow: hidden;
}

.left_padding {
    padding-top: 12%;
}

.form-group {
    margin-bottom: 10px;
}


.pt-4 {
    padding-top: 4% !important;
    padding-bottom: 5% !important;
}

.tc {
    text-align: center;
}

.fb {
    font-weight: bold;
}

.pt-15 {
    padding-top: 15px;
}

.pt-30 {
    padding-top: 30px;
}

.login-btn {
    width: 100%;
    background-color: var(--blue);
    color: var(--white);
    padding: 5px 5px;
    margin: 10px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

h4 {
    font-weight: bolder;
}

.pb-15 {
    padding-bottom: 15px;
}

.pb-30 {
    padding-bottom: 30px;
}